import { map, mergeMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { ofType, combineEpics } from "redux-observable";
import { from, of } from "rxjs";
import {
    HANDLE_TOKEN,
    HANDLE_TOKEN_SUCCESS,
    HANDLE_INPUT_EVENT,
    HANDLE_INPUT_SUCCESS,
    HANDLE_SUBMIT,
    HANDLE_SUBMIT_SUCCESS,
    HANDLE_SUBMIT_FAILED,
    GET_CLIENTS,
    GET_CLIENTS_SUCCESS,
    GET_APPOINTMENTS,
    GET_APPOINTMENTS_SUCCESS,
    GET_APPOINTMENTS_PROCESS,
    APPOINTMENTS_NOT_FOUND,
    FETCH_USER_SYNC,
    LOG_OUT,
} from "./actions";
import tokenValidator from "../utils/tokenValidator";
import { restructDataAppointment } from "../utils/restructDataAppointment";
import config from "../main-config.json";
const FORM_URLENCODED_HEADERS = {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
};
const NETWORK_ERROR_MESSAGE = "Network response failed. Please try again.";
const URL = config.autohelp_api_url;
const handleTokenEpic = (action$) =>
    action$.pipe(
        ofType(HANDLE_TOKEN),
        map(() => {
            const token = tokenValidator();
            if (token) {
                if (token === "gameoveradmin") {
                    return {
                        type: HANDLE_TOKEN_SUCCESS,
                        payload: {
                            token,
                            applicationState: "adminPanel",
                        },
                    };
                } else {
                    return {
                        type: HANDLE_TOKEN_SUCCESS,
                        payload: {
                            token,
                            applicationState: "userPanel",
                        },
                    };
                }
            }
            return {
                type: "HANDLE_TOKEN_FAILED",
            };
        })
    );

const handleInputEpic = (action$, state$) =>
    action$.pipe(
        ofType(HANDLE_INPUT_EVENT),
        map((action) => {
            const { name, value } = action.payload;

            let newInputs = state$.value.formInputs;

            newInputs[name] = value;

            return {
                type: HANDLE_INPUT_SUCCESS,
                payload: newInputs,
            };
        })
    );

const handleSubmitEpic = (action$, state$) =>
    action$.pipe(
        ofType(HANDLE_SUBMIT),
        mergeMap((action) => {
            console.log(action.payload);

            let username = state$.value.formInputs.username;
            let password = state$.value.formInputs.password;

            let data = new URLSearchParams();
            data.append("username", username);
            data.append("password", password);

            return ajax({
                url: URL + "/login.php",
                method: "POST",
                body: data.toString(),
                headers: FORM_URLENCODED_HEADERS,
            }).pipe(
                map((response) => {
                    console.log(response);
                    if (response.response.message === "Successful login.") {
                        console.log("success");
                        localStorage.token = response.response.jwt;

                        if (username === "gameoveradmin") {
                            return {
                                type: HANDLE_SUBMIT_SUCCESS,
                                payload: {
                                    username,
                                    applicationState: "adminPanel",
                                },
                            };
                        } else {
                            return {
                                type: HANDLE_SUBMIT_SUCCESS,
                                payload: {
                                    username,
                                    applicationState: "userPanel",
                                },
                            };
                        }
                    } else {
                        return {
                            type: HANDLE_SUBMIT_FAILED,
                        };
                    }
                }),
                catchError(() => {
                    return of({
                        type: HANDLE_SUBMIT_FAILED,
                    });
                })
            );
        })
    );

// const getClientsEpic = (action$, state$) =>
const getClientsEpic = (action$) =>
    action$.pipe(
        ofType(GET_CLIENTS),
        mergeMap(() => {
            return from(
                fetch(URL + "/functions/booking/fetchUsers.php", {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                    },
                }).then((response) => {
                    if (!response.ok) {
                        throw new Error(NETWORK_ERROR_MESSAGE);
                    }
                    return response.json();
                })
            ).pipe(
                map((response) => {
                    return {
                        type: GET_CLIENTS_SUCCESS,
                        payload: response,
                    };
                }),
                catchError(() => {
                    return of({
                        type: "GET_CLIENTS_FAILED",
                    });
                })
            );
        })
    );

const fetchAppointmentsEpic = (action$, state$) =>
    action$.pipe(
        ofType(GET_APPOINTMENTS),
        mergeMap((action) => {
            const { selectedClient, user } = state$.value;

            const client = selectedClient || user; // If Admin is logged in SelectedClient will be active
            const bodyData = new URLSearchParams({
                ...action.payload,
                client,
            }).toString();

            return from(
                fetch(URL + "/functions/booking/fetchSingle.php", {
                    method: "POST",
                    headers: FORM_URLENCODED_HEADERS,
                    body: bodyData,
                }).then((response) => {
                    if (!response.ok) {
                        throw new Error(NETWORK_ERROR_MESSAGE);
                    }
                    return response.json();
                })
            ).pipe(
                map((data) => {
                    if (data.message === "Not Found!") {
                        return {
                            type: APPOINTMENTS_NOT_FOUND,
                        };
                    } else {
                        return {
                            type: GET_APPOINTMENTS_PROCESS,
                            payload: {
                                appointments: data.appointments,
                                test: data.test,
                            },
                        };
                    }
                }),
                catchError(() => {
                    return of({
                        type: "GET_APPOINTMENTS_FAILED",
                    });
                })
            );
        })
    );

// const restructAppointmentsEpic = (action$, state$) =>
const restructAppointmentsEpic = (action$) =>
    action$.pipe(
        ofType(GET_APPOINTMENTS_PROCESS),
        map((action) => {
            let newAppointments = action.payload.appointments;
            let totalTeams = newAppointments.length;
            let totalPlayers = newAppointments.reduce(
                (total, player) => (total += parseInt(player.numberOfPlayers)),
                0
            );

            if (newAppointments.length > 0) {
                newAppointments = restructDataAppointment(newAppointments);
            }

            return {
                type: FETCH_USER_SYNC,
                payload: {
                    newAppointments,
                    test: action.payload.test,
                    totalTeams,
                    totalPlayers,
                },
            };
        })
    );

const fetchUserLastTimeSyncEpic = (action$, state$) =>
    action$.pipe(
        ofType(FETCH_USER_SYNC),
        mergeMap((action) => {
            const client = state$.value.selectedClient;
            const user = state$.value.user;
            const newAppointments = action.payload.newAppointments;
            const test = action.payload.test;
            const totalPlayers = action.payload.totalPlayers;
            const totalTeams = action.payload.totalTeams;

            let data;
            if (user === "gameoveradmin") {
                data = {
                    customerID: client,
                };
            } else {
                data = {
                    customerID: user,
                };
            }

            const bodyData = new URLSearchParams(data).toString();
            return from(
                fetch(URL + "/functions/booking/fetchUserSync.php", {
                    method: "POST",
                    headers: FORM_URLENCODED_HEADERS,
                    body: bodyData,
                }).then((response) => {
                    if (!response.ok) {
                        throw new Error(NETWORK_ERROR_MESSAGE);
                    }
                    return response.json();
                })
            ).pipe(
                map((response) => {
                    return {
                        type: GET_APPOINTMENTS_SUCCESS,
                        payload: {
                            newAppointments,
                            test,
                            sync: response.sync,
                            time: response.time,
                            totalTeams,
                            totalPlayers,
                        },
                    };
                }),
                catchError(() => {
                    return of({
                        type: "FETCH_USER_SYNC_FAILED",
                    });
                })
            );
        })
    );
/**
 @deprecated
 */
// const handleLogOutEpic = (action$, state$) =>
const handleLogOutEpic = (action$) =>
    action$.pipe(
        ofType(LOG_OUT),
        map(() => {
            localStorage.removeItem("token");
            return {
                type: "INITIALIZATION_APP",
            };
        })
    );

const rootEpic = combineEpics(
    handleTokenEpic,
    handleInputEpic,
    handleSubmitEpic,
    getClientsEpic,
    fetchAppointmentsEpic,
    restructAppointmentsEpic,
    fetchUserLastTimeSyncEpic,
    handleLogOutEpic
);

export default rootEpic;
