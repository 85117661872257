export const hasPropertyHour = (dataAppointment) => {
    if (Object.prototype.hasOwnProperty.call(dataAppointment[0], "hour")) {
        return true;
    } else {
        return undefined;
    }
};

export const restructDataAppointment = (dataAppointment) => {
    const hasHour = hasPropertyHour(dataAppointment);
    return dataAppointment.reduce((acc, obj) => {
        let currentName = obj.roomName;

        let counter = 1;

        if (!Object.prototype.hasOwnProperty.call(acc, currentName)) {
            if (hasHour) {
                acc[currentName] = {
                    numberOfPlayers: parseInt(obj.numberOfPlayers),
                    teams: counter,
                    hour: [
                        {
                            ts: obj.hour,
                            players: obj.numberOfPlayers,
                        },
                    ],
                };
            } else {
                acc[currentName] = {
                    numberOfPlayers: parseInt(obj.numberOfPlayers),
                    teams: counter,
                };
            }
        } else {
            acc[currentName].numberOfPlayers += parseInt(obj.numberOfPlayers);
            acc[currentName].teams += counter;
            if (hasHour) {
                acc[currentName].hour.push({
                    ts: obj.hour,
                    players: obj.numberOfPlayers,
                });
            }
        }
        return acc;
    }, {});
};
